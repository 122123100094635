import styled,{css} from 'styled-components';

export const Table = styled.table`
    width: 100%;
    border: none;
    tbody{
        tr{
            background-color:#f7f7f7;
            &:nth-child(even) {
                background-color: #ffffff;
            }

            td{
                color:#000000;
                border: none;
                font-size: 13px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.19px;
                width: 160px;
                @media (max-width: 767px){
                    width:auto;
                }
                &:first-child {
                    text-align: left;
                    @media (max-width: 767px){
                        width:170px;
                    }
                }
            }
        }
    }
`;

export const Container = styled.div`
    width: 100%;
    overflow: auto;
`;

export const TdCustom = styled.td`
    @media(max-width:992px){
        ${({show}) => show && css`
            display:none;
        `}
    }
`;