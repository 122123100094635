import React from 'react';
import {Container} from './styles';

export default function CarImage({image}) {
    return (
        <Container>
            {image && <img src={image.url} alt={image.alt} />}
        </Container>
    );
}
