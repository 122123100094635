import styled from 'styled-components';

export const Container = styled.div`
    width: 227px;
    height: 87px;
    margin: 0 auto;
    overflow: hidden;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-top:15px;
    margin-bottom:20px;
    img {
        width: 100%;
    }
`;