import React, { useEffect, useState, version } from 'react';
import Select from '../Select';
import Accordion, {AccordionItem} from '../Accordion';
import Popover from '../Popover';
import axios from 'axios';

import Table, {TdTable} from '../Table';
import CarImage from '../CarImage';
import Features from '../Features';
import DeskFeatures, {DeskFeatureItem} from '../DeskFeatures';

import {IconArrowLeft, IconArrowRight, StartIcon, InfoIcon} from '../Icons';
import {HeaderText, Content, ArrowContainer, Item, TextCol, ItemSelectsContainer, Stars, FeaturesContainer} from './styles';
import Carousel,{consts} from "react-elastic-carousel";

export default function Comparator ({modelId}){

    const [specifications, setSpecifications] = useState();
    const [models, setModels] = useState([]);
    const [popoverShow, setPopoverShow] = useState(false);
    const [carouselIndex, setCaruselIndex] = useState(0);


    /*
        se sabe que se pueden comparar hasta 3 modelos, pero en el caso que se desee modificar esta cantidad,
        es posible a través de la variable "maxModelsToCompare";
    */
    const maxModelsToCompare = 3;
    const maxFeaturesPerVersion = 3;

    const [versions, setVersions] = useState({});
    const [selecteds, setSelecteds] = useState({
        models:{},
        versions:{},
        specifications:{},
        keys: [], // será de utilizad para recorrer cada item.
        featuresKeys: [] //usado para correr cada feature
    })

    const mapVersions = (index, selectedModelIndex, selectedVersionIndex) => {
        /*
            Esta función evita que se repitan las versiones entre selects
        */
       let vers = versions[index];
        let mapped = !vers ? [] : vers.map(item => ({
            label: item.name,
            value: item.id,
            hidden:false,
        }))

        const keys = selecteds.keys.filter(item => String(item) !== String(index));
        for (const key in keys) {
            const keyIndex = keys[key];
            let modelIndex = selecteds.models[keyIndex];
            if(models[modelIndex]?.id === models[selecteds.models[index]]?.id){
                mapped = mapped.map(item => {
                    if(versions[keyIndex] && versions[keyIndex][selecteds.versions[keyIndex]]?.id === item.value){
                        return {
                            ...item,
                            hidden: true
                        }
                    }
                    return item;
                });
            }
        }
        return mapped;
    }

    const getUnifiedSpecifications = () => {
        const keys = selecteds.keys;
        let unified = [];
        for (const key in keys) {
            const index = keys[key];
            selecteds.specifications[index].forEach(item => {
                if(!unified.includes(item.specification_id)){
                    unified.push(item.specification_id);
                }
            });
        }
        return unified;
    }

    const handleChangeSelected = (index, selectedIndex, type = 'models') => {        
        let isValidIndex = (selectedIndex !== null && selectedIndex !== undefined) ? true : false;

        setSelecteds(prev => {
            let newState = {
                ...prev,
                [type]: {
                    ...prev[type],
                    [index]: isValidIndex ? selectedIndex : -1,
                }
            };
            if(type === 'models') {
                newState.versions[index] = [];
                newState.specifications[index] = [];
            }
            return newState
        });
        
        if(type === 'models' && isValidIndex) handleSetVersions(index, selectedIndex);
        if(type === 'versions' && isValidIndex ) handleSetSpecifications(index, selectedIndex);
    
    }

    const handleSetVersions = (index, selected, returnOnly) => {
        let model = models[selected];
        let versions = model ? model.versions : [];
        if(returnOnly) return versions;
        setVersions(prev => ({
            ...prev,
            [index]: versions
        }))
    }

    const handleSetSpecifications = (index, selected) => {
        let version = versions[index][selected];
        let specifications = version ? version.specifications : [];
        setSelecteds(prev => ({
            ...prev,
            specifications: {
                ...prev.specifications,
                [index]: specifications
            }
        }))
    }
    
    const hasSpecifications = () => {
        if(!selecteds?.specifications) return;
        const keys = selecteds.keys;
        for (const key in keys) {
            const index = keys[key];
            if(selecteds.specifications[index].length > 0) return true;
        }
        return false;
    }

    const getResource = async resource => {
        let records = await axios.get(`/api/vehicles/${resource}/`);
        return records ? records.data : [];
    }

    useEffect(() => {
        /* 
            El siguiente procedimiento permite identificar a cada uno de los selects,
            tanto para modelos como para versiones.
        */
       let indexRecords = {};
       let defaultModelValues = {};
       let defaultModelValue = modelId && specifications && models? models.findIndex(i => String(modelId) === String(i.id)) : -1;
       let versions = {};
       let specs = {};
       let keys = []
       let featuresKeys = [];

       for (let i = 0; i < maxModelsToCompare; i++) {
           indexRecords[i] = -1; // -1 es el item seleccionado por defecto.
           defaultModelValues[i] = defaultModelValue;
           keys.push(i);
           versions[i] = defaultModelValue >  -1 ? handleSetVersions(i, defaultModelValue, true) : [];
           specs[i] = [];
       }
       for (let i = 0; i < maxFeaturesPerVersion; i++) {
            featuresKeys.push(i);
        }
       setVersions(versions);
       setSelecteds({
           models:defaultModelValues,
           versions: indexRecords,
           specifications: specs,
           keys,
           featuresKeys
       });
    },[modelId, models, specifications]);

    useEffect(() => {
        getResource('models')
        .then(res => {
            setModels(res);
        });

        getResource('specifications')
        .then(res => {
            setSpecifications(res);
        });
    },[]);

    const mappedModels = models => {
        return models.map(item => ({
            value: item.id,
            label: item.name,
            hidden: false
        }));
    }

    const existFeatures = () => {
        for (let i = 0; i < maxModelsToCompare; i++) {
            if(!(models && selecteds?.versions && versions && versions[i])) break;
            if(versions[i][selecteds.versions[i]] && versions[i][selecteds.versions[i]].compare_features){
                if(versions[i][selecteds.versions[i]].compare_features.length > 0) return true;
            }            
        }
        return false;
    }

    return (
        <div className="">
            <div className="comparator-header">
                <div className="wrapper">
                    <h2>Compara vehículos</h2>
                    <HeaderText>
                        <small className="main-text">
                            Puedes comparar hasta tres vehículos.
                            {/* <InfoIcon data-rule='popover' onClick={() => setPopoverShow(!popoverShow)} />
                            <Popover
                                show={popoverShow}
                                text="Estos valores incluyen IVA. Para otras versiones consulte al concesionario oficial Toyota. Consultar equipamiento y disponibilidad según modelo y versión. Toyota Argentina S.A. se reserva el derecho de modificar las especificaciones técnicas y/o estáticas del vehículo en cualquier momento. Foto no contractual. Para más información consulte al concesionario oficial Toyota."
                            /> */}
                        </small>
                    </HeaderText>
                </div>
            </div>

            
            
            <div className="wrapper">
                <Content className="row">
                    <TextCol borderBottom mt={3}>
                        <p>
                            Elige los vehículos<br/>
                            que quieras<br />
                            comparar
                        </p>
                    </TextCol>
                        {models?.length > 0 && (
                        <Carousel
                            renderArrow={CustomArrow}
                            pagination={false}
                            itemsToShow={1}
                            itemsToScroll={1}
                            enableMouseSwipe={false}
                            itemPadding={[[0,0]]}
                            breakPoints={[
                                { width: 1, itemsToShow: 1 },
                                { width: 653, itemsToShow: 3 },
                            ]}
                            onChange={(_, pageIndex) => setCaruselIndex(pageIndex)}
                            >
                            {models && selecteds?.keys?.map((key) => (
                                <Item key={key}>
                                    {versions[key] && <CarImage image={versions[key][selecteds.versions[key]]?.image} />}
                                    <ItemSelectsContainer>
                                        <Select placeholder="Selecciona un Toyota"
                                                selected={selecteds.models[key]}
                                                editable={!modelId}
                                                onChange={(index) => handleChangeSelected(key, index)}
                                                options={mappedModels(models)}
                                            />
                                        <br />
                                        <Select placeholder={selecteds.models[key] === -1 ? "Elige el modelo":"Elige la versión"}
                                                selected={selecteds.versions[key]}
                                                editable={true}
                                                disabled={selecteds.models[key] === -1}
                                                onChange={(index) => handleChangeSelected(key, index, 'versions')}
                                                options={mapVersions(key)}
                                            />
                                    </ItemSelectsContainer>
                                    {versions[key] && <Features items={versions[key][selecteds.versions[key]]?.compare_features} />}
                                </Item>
                            ))}
                        </Carousel>
                    )}
                </Content>

                <FeaturesContainer>
                    <TextCol alignBottom>
                        <p>
                            {/* <Stars><StartIcon /> <StartIcon /> <StartIcon /></Stars> */}
                            Características<br/>

                            distintivas del<br/>
                            modelo seleccionado
                        </p>
                    </TextCol>
                    {models?.length > 0 && (
                        <DeskFeatures>
                            <tbody>
                                {existFeatures() && selecteds.featuresKeys.map((_,f) => (
                                    <DeskFeatureItem key={f}>
                                        {selecteds.keys.map((_,k) => (
                                            <td key={k}>
                                                {versions[k][selecteds.versions[k]] &&
                                                versions[k][selecteds.versions[k]].compare_features[f] &&
                                                versions[k][selecteds.versions[k]].compare_features[f].name}
                                                
                                            </td>
                                        ))}
                                    </DeskFeatureItem>
                                ))}
                            </tbody>
                        </DeskFeatures>
                    )}
                </FeaturesContainer>
                <div>
                    {specifications?.length > 0 && hasSpecifications() && specifications.map(item => (
                        <Accor
                            key={item.id}
                            unified={getUnifiedSpecifications()}
                            specification={item}
                            selecteds={selecteds.specifications}
                            carouselIndex={carouselIndex} />
                    ))}
                </div>
            </div>
        </div>
    );
}

function Accor({specification, selecteds, unified, carouselIndex}) {

    /*
        Este componente se encarga de controlar/renderizar un acordión en particular,
        permitiendo la separación por secciones (especificaciones que no tienen padre) y descongestionando
        el componente principal.
    */

    const [current,setCurrent] = useState(-1);

    const changeCurrent = id => {
        setCurrent(current === id ? -1 : id);
    }

    function checkItem(specification, unified){
        /*
            Esta funcion tiene una complegidad muy alta: O(log a*(b*c)).
            Pero es muy importante, ya que se encarga de verificar si una
            especificación puede mostrarse o no, y para ello debe recorer sus hijos
            de forma recursiva en cada vuelta de bucle.
        */
        if(specification.children.length > 0) {
            for (let i = 0; i < specification.children.length; i++) {
                if(checkItem(specification.children[i], unified)) return true;
            }
        }
        if(unified.includes(specification.id)) return true;
        return false;
    }

    if(!specification || !unified) return null;

    return (
        <>
            {checkItem(specification, unified) && (
                <Accordion title={specification.name}>
                    <AccorTree
                        specification={specification}
                        checked={true}
                        unified={unified}
                        checkItem={checkItem}
                        current={current}
                        changeCurrent={changeCurrent}
                        selecteds={selecteds}
                        carouselIndex={carouselIndex}
                    />
                </Accordion>
            )}
        </>
    );
}

function AccorTree(props) {
    if(!props.specification || props.specification.children.length < 1) return null;
    if(!(props.checked || props.checkItem(props.specification, props.unified))) return null;

    /*
        el siguiente array (toPrint) será utilizado para renderizar los componentes del acordión correctamente.
        esto evita que se repitan las especificaciones y que NO se omitan los casos particulares (cuando una version
        utiliza una expecificación del nivel 2)
    */
    let toPrint = [];

    props.specification.children.forEach(item => {
        /*
            si la especificación tiene por lo menos 1 hijo, entonces se ejecuta una recursión,
            de lo contrario, se pushea su padre dentro del array "toPrint". De esta manera
            se pueden agrupar especificaciones que correspondan al mismo nivel.
        */
        if(item.children.length === 0) {
            // antes de pushear el item padre se verifica que ya no esté agregado.
            if(!toPrint.find(i => i.data.specification.id === props.specification.id)){
                toPrint.push({
                    toItem: true,
                    data: props
                });
            }
        }
        toPrint.push({
            toItem: false,
            data: {
                ...props,
                checked: false,
                specification:item,
            }
        });
    });
    return (
        <>
            {toPrint.map(item => {
                let Comp = item.toItem ? AccorTreeItem : AccorTree;
                return <Comp
                            key={item.data.specification.id}
                            {...item.data}
                        />
            })}
        </>
    );
}

function AccorTreeItem({specification, current, changeCurrent, selecteds, checkItem, unified, carouselIndex}) {
    if(!specification || !selecteds) return null;

    const findOnIndex = (index, id) => {
        const selected =  selecteds[index].find(item => item.specification_id === id);
        return (selected ? selected.value : '-');
    }

    return (
        <AccordionItem expandId={specification.id} current={current} title={specification.name} onClick={() => changeCurrent(specification.id)}>
            <Table>
                {specification.children.map(item => {
                    if(!checkItem(item, unified) || item.children.length > 0) return;
                    return <tr key={item.id}>
                        <td>{item.name}</td>
                        {Object.keys(selecteds).map((_,k) => (
                            <TdTable show={!(carouselIndex === k)} key={k}>{findOnIndex(k, item.id)}</TdTable>
                        ))}
                    </tr>
                })}
            </Table>
        </AccordionItem>
    );
}



function CustomArrow({ type, onClick, isEdge }) {
    const pointer = type === consts.PREV ? (<IconArrowLeft />) : (<IconArrowRight />);
    return (
        <ArrowContainer toPrev={type === consts.PREV} onClick={onClick} disabled={isEdge}>
            {pointer}
        </ArrowContainer>
    )
}



