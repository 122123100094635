import React from 'react';
import {Container, Item, Text} from './styles';
import {StartIcon} from '../Icons'

export default function Features({items}){
    return <Container>
        <Text>Características distintivas del modelo seleccionado</Text>
        {items?.map(item => (
            <Item key={item.id}><span><StartIcon /></span> {item.name}</Item>
        ))}
    </Container>
}