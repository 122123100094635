import React from "react";
import { motion, AnimatePresence } from "framer-motion";

export const CurrencyFormat = value => {
    if (!value) return "$ 0";
    const val = parseInt(value);
    return "$" + val.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const isRutValid = rut => {
    if (!rut) return false;
    
    let sum = 0;
    const rut_clean = String(rut)
        .toLowerCase()
        .match(/[\dk]+/g)
        .join("");
    const rut_body = rut_clean.slice(0, -1);
    const rut_vd = rut_clean.slice(-1);

    const multipliers = [2, 3, 4, 5, 6, 7];

    if (rut_body.length < 7) return false;

    for (let i = 0; i < rut_body.length; i++) {
        const num = rut_body[rut_body.length - 1 - i];
        const by =
            i < multipliers.length
                ? multipliers[i]
                : multipliers[i % multipliers.length];
        const subtotal = num * by;
        sum += num * by;
    }

    const vd = 11 - (sum % 11);
    if (vd === 10 && rut_vd === "k") return true;
    if (vd === 11 && rut_vd === "0") return true;
    if (String(vd) === rut_vd) return true;

    // console.log({rut, rut_clean, rut_body, rut_vd, sum, vd});

    return false;
};

export const AnimationSlideToggle = ({ isOpen, children }) => (
    <AnimatePresence initial={false}>
        {isOpen && (
            <motion.section
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                    open: { opacity: 1, height: "auto" },
                    collapsed: { opacity: 0, height: 0 }
                }}
                transition={{ duration: 0.4 }}
            >
                {children}
            </motion.section>
        )}
    </AnimatePresence>
);

export const AnimationFadeToggle = ({ isOpen, children }) => (
    <AnimatePresence initial={false}>
        {isOpen && (
            <motion.section
                key="content"
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                    open: { opacity: 1 },
                    collapsed: { opacity: 0 }
                }}
                style={{
                    zIndex: 500,
                    position: "fixed"
                }}
                transition={{ duration: 0.3 }}
            >
                {children}
            </motion.section>
        )}
    </AnimatePresence>
);
