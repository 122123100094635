import React from 'react';
import {Container, Table as TableStyles, TdCustom } from './styles';

export default function Table({children, className}){
    return (
        <Container>
            <TableStyles className={className}>
                <tbody>{children}</tbody>
            </TableStyles>
        </Container>
    );
}

export const TdTable = ({children, show}) => {
    return <TdCustom show={show}>
        {children}
    </TdCustom>
}