import React, { useEffect } from 'react';
import {Container, Title, ItemTitle, ItemIcon, ItemBody} from './styles'
import {AnimationSlideToggle} from '../../../utils';


export default function Accordion({title, children}) {
    return (
        <Container>
            <Title>
                <h3>{title}</h3>
            </Title>
            {children}
        </Container>
    );
}

export function AccordionItem({expandId, current, children, title, onClick}) {
    const isExpanded = self => {
        return current === self;
    }


    useEffect(() => {
        //handleToggle(expandId);
    },[expandId, current]);

    return (
        <div>
            <ItemTitle expanded={isExpanded(expandId)} onClick={onClick}>
                <ItemIcon expanded={isExpanded(expandId)}></ItemIcon>
                <h4>{title}</h4>
            </ItemTitle>
            <AnimationSlideToggle isOpen={isExpanded(expandId)}>
                <ItemBody expanded={isExpanded(expandId)}>
                    {children}
                </ItemBody>
            </AnimationSlideToggle>
        </div>
    );
}