import React from 'react';
import { Table } from './styles';

export default function DeskFeatures (props){
    return <Table {...props} />;
}

export const DeskFeatureItem = ({children}) => {
    return (
        <tr>{children}</tr>
    );
}