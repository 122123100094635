import styled from 'styled-components';

export const Container = styled.div`
    display:none;
    @media(max-width: 992px){
        width: 100%;
        margin-top: 26px;
        display:block;
    }
`;

export const Item = styled.div`
    display:none;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: -0.22px;
    color: #333333;
    box-sizing: border-box;
    padding: 3px 0;
    span{
        margin-right:9px;
    }
    @media(max-width: 992px){
        display: block;
    }
`;

export const Text = styled.div`
    display:none;
    margin-top:25px;
    margin-bottom: 10px;
    font-size: 15.1px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #9b9b9b;
    @media(max-width:767px){
        display: block;
    }
`;
