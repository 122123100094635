import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export default function Popover({text, show}) {

    const [popoverShow, setPopoverShow] = useState(null);
    const onDocumentClick = (e) => {
        if(e.target.dataset.rule !== 'popover') {
            setPopoverShow(false);
        }
    }

    useEffect(() => {
        if(popoverShow === true) {
            document.addEventListener('click', onDocumentClick)
        }
        return () => {
            document.removeEventListener('click', onDocumentClick)
        }
    },[popoverShow, setPopoverShow, onDocumentClick]);

    useEffect(() => {
        setPopoverShow(show === popoverShow ? !show : show);
    }, [show, setPopoverShow]);
    
    return (
        <PopoverStyle show={popoverShow} data-rule="popover">
            <p data-rule="popover">{text}</p>
        </PopoverStyle>
    );
}

const PopoverStyle = styled.div`
    display:${({show}) => show ? 'block' : 'none'};
    background-color:#fff;
    box-shadow: 1px 5px 40px 0 rgba(0,0,0,.21);
    box-sizing:border-box;
    padding: 2rem 2rem 2.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 17rem;
    position: absolute;
    z-index: 2;
    border-radius: 6px;
    font-weight: 500;
    font-size: .6rem;
    line-height: 1.5;
    right: -8.5rem;
    @media (max-width: 700px){
        left: 50%;
        margin-left: -8.5rem;
    }
`;