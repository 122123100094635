import styled,{css} from 'styled-components'

export const ArrowContainer = styled.div`
    display:none;
    @media (max-width: 992px){
        display:flex;
        height:100%;
        justify-content: center;
        width:100%;
        margin:0;
        z-index:1;
        position:absolute;
        max-width: 30px;
        height: ${({wrapperHeight}) => wrapperHeight+'px'};
        ${({toPrev}) => toPrev === true ? css`
            left: 8px;
        ` : css`
            right: 8px;
        `}
        svg{
            margin-top:38px;
        }
    }
`

export const TextCol = styled.div`
    width:340px;
    p{
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.35;
        letter-spacing: normal;
        color: #9b9b9b;
        &:first-child{
            padding-bottom: 13px;
            margin-top:${({mt}) => (mt >= 0 ? mt + 'rem' : 'auto')};
            ${({borderBottom}) =>  borderBottom && css `
                border-bottom: 1px solid #efeeef;
            `}
        }
    }
    ${({alignBottom}) => alignBottom && css`
        align-self: flex-end;
    `}
    @media(max-width: 992px){
        display: none;
    }
`;

export const Stars = styled.span`
    margin-bottom:8px;
    display:block;
`;

export const Content = styled.div`
    min-height: 175px;
    box-sizing:border-box;
    padding: 20px 0;
    max-width: 1100px;
    margin: 0 auto;
`

export const HeaderText = styled.div`
    svg {
        margin-left:.25rem;
        cursor: pointer;
    }
    .main-text{
        position:relative;
        vertical-align:middle;
        @media (max-width: 700px){
            position: initial;
        }
        &:focus{
            div{
                display:none;
            }
        }
    }
`

export const Item = styled.div`
    width:100%;
    height: auto;
    margin:0 10px;
`

export const ItemSelectsContainer = styled.div`
    width:auto;
    overflow:auto;
    @media (max-width: 650px){
        width:100%;
        margin:0;
    }
`

export const FeaturesContainer = styled.div`
    display: flex;
    border-bottom: 1px solid #efeeef;
    margin-bottom: 30px;
`;