import styled,{css} from 'styled-components';
export const Container = styled.div`
    margin-bottom: 20px;
`;

export const Title = styled.div`
    h3 {
        font-size: 12.6px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.07px;
        color: #191919;
    }
    border-bottom:2px solid #d8d8d8;
    padding:9px 0;
`

export const ItemTitle = styled.div`
    display:flex;
    align-items:center;
    transition: all .4s ease-in-out;
    border-bottom:1px solid ${({expanded}) => expanded ? 'transparent' : '#d8d8d8'};
    box-sizing:border-box;
    padding:10px 0;
    cursor: pointer;
    h4{
        font-size: 12.6px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: 0.07px;
        color: #191919;
    }
`;

export const ItemIcon = styled.div`
    box-sizing: border-box;
    height:100%;
    font-size: 25px;
    font-weight: 700;
    width:22px;
    color:#9b9b9b;
    &::before{
        transition: opacity .7s ease-in-out;
        content: "${({expanded}) => expanded  === true ? '-' : '+'}";
    }
`;

export const ItemBody = styled.div`
    ${({expanded}) => expanded && css`
        box-sizing:border-box;
        padding-bottom: 15px;
        border-bottom: 1px solid #d8d8d8;
    `}
`;