import styled from 'styled-components';

export const Table = styled.table`
    width: 100%;
    border: none;
    margin-top: 15px;
    tbody{
        tr{
            border-bottom: 1px solid #efeeef;
            td{
                border: none;
                font-size: 15px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.6;
                letter-spacing: -0.22px;
                text-align: center;
                color: #000000;
                width: 194px;
                @media (max-width: 992px){
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    @media(max-width: 992px){
        display:none;
    }
`;