import React from 'react';

export const SelectIcon = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#191919">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <g>
                                            <g>
                                                <g>
                                                    <path d="M181.212 14.5c-.289 0-.548-.164-.658-.414-.11-.25-.049-.536.154-.728l3.068-2.876-3.068-2.877c-.243-.265-.227-.662.037-.909s.688-.263.971-.035l3.576 3.349c.277.261.277.683 0 .944l-3.576 3.348c-.133.126-.314.197-.504.198z" transform="translate(-718 -417) translate(150 119) translate(0 35) translate(74 158) translate(279) translate(0 5) translate(36 92) rotate(90 183 10.5)"/>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export const StartIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" {...props}>
            <g fill="none" fillRule="evenodd">
                <g fill="#000">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <g>
                                        <path d="M22.99 0c-.09.005-.168.067-.196.157l-1.09 3.423h-3.487c-.094 0-.177.063-.206.157-.03.093.002.195.077.253l2.833 2.17-1.089 3.543c-.029.093.003.196.08.254.076.058.179.058.255 0L23 7.785l2.833 2.17c.076.059.18.059.255.001.077-.058.109-.16.08-.254l-1.09-3.543 2.833-2.17c.076-.058.107-.16.078-.253-.029-.094-.112-.156-.206-.157h-3.487L23.206.157c-.03-.097-.119-.162-.217-.157z" transform="translate(-243 -525) translate(150 122) translate(0 32) translate(73 361) translate(2 10)"/>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export function IconArrowLeft(props) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="21" viewBox="0 0 12 21" {...props}>
        <path fill="#9A9A9A" fillRule="evenodd" d="M10.29 21c.694-.002 1.316-.43 1.58-1.086.264-.655.118-1.408-.369-1.911l-7.363-7.55L11.5 2.901c.584-.697.545-1.738-.09-2.386-.633-.65-1.65-.69-2.33-.093L.499 9.213c-.665.686-.665 1.793 0 2.479l8.582 8.79c.32.329.755.516 1.21.518z"/>
    </svg>
}

export function IconArrowRight(props) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="21" viewBox="0 0 12 21" {...props}>
        <path fill="#9A9A9A" fillRule="evenodd" d="M1.71 0C1.015.002.393.43.13 1.086c-.264.655-.118 1.408.369 1.911l7.363 7.55L.5 18.099c-.584.697-.545 1.738.09 2.386.633.65 1.65.69 2.33.093l8.582-8.79c.665-.686.665-1.793 0-2.479L2.92.518C2.6.19 2.164.003 1.71 0z"/>
    </svg>
}
export function InfoIcon(props) {
    return <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" {...props}>
        <g fill="none" fillRule="evenodd" transform="translate(1 1)">
            <circle cx="7.5" cy="7.5" r="7.5" stroke="#979797"/>
            <path fill="#9B9B9B" d="M7.078 6.24h.817V11h-.817V6.24zM7.503 5a.482.482 0 0 1-.36-.147A.49.49 0 0 1 7 4.497.488.488 0 0 1 7.503 4a.48.48 0 0 1 .353.144.48.48 0 0 1 .144.353.49.49 0 0 1-.144.356.474.474 0 0 1-.353.147z"/>
        </g>
    </svg>;
}