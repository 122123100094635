import styled,{css} from 'styled-components';

export const CustomSelect = styled.div`
    width:194px;
    margin: 0 auto;
    position:relative;
    border-bottom: 1px solid #efeeef;
    @media(max-width: 992px){
        width:100%;
    }
`;

export const Icon = styled.span`
    cursor: pointer;
    svg{
        transition: .4s all ease-in-out;
    }
`;

export const SelectIndicator = styled.button`
    cursor: pointer;
    letter-spacing: 0.07px;
    color: #191919;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-size:14px;
    width:100%;
    padding:6px 0;
    display:flex;
    justify-content:space-between;
    box-sizing: border-box;
    border-radius: 0;
    border:none;
    background-color:transparent;
    span{
        z-index: -1;
    }
    &:focus{
        outline: none;
    }
    ${({focused}) => focused && css`
        svg{
            transform: rotate(180deg);
        }
    `}
    ${({editable}) => !editable && css`
        cursor:not-allowed;
    `}
    
    ${({disabled}) => disabled && css`
        opacity: .2;
    `}

`;

export const SelectItems = styled.div`
    position:absolute;
    width:100%;
    background-color: #ffffff;
    box-shadow: 2px 8px 20px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
    padding: 6px;
    transition: all .4s ease-in-out;
    opacity:1;
    top: calc(100% - 1px);
    z-index: 1;
    max-height: 260px;
    border-radius:0 0 3px 3px;
    overflow-y:auto !important;
    ${({focused}) => !focused && css`
        display: none;
        opacity:0;
    `}

    ::-webkit-scrollbar{
        width: 15px;
        appearance: none;
    }
    ::-webkit-scrollbar-thumb {
        background: #d8d8d8;
        border-radius: 7.5px;
        position:absolute;
        left: -40px;
        border: 4.5px solid white;
    }
`

export const Item = styled.div`
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.07;
    letter-spacing: normal;
    cursor:pointer;
    color: ${({selected}) => selected ? '#ec0000' : '#191919'};
    width: 100%;
    ${({hidden}) => hidden && css`
        display: none;
    `}
`