import React, { useEffect, useRef, useState } from 'react';

import {SelectIcon} from '../Icons'
import {CustomSelect, Icon, SelectIndicator, SelectItems, Item} from './styles'

export default function Select({options, placeholder, defaultValue, onChange, disabled, selected, editable}) {

    const [focused, setFocused] = useState(false);


    const onDocumentClick = (e) => {
       if(e.target.dataset.rule !== 'custom-select') {
           setFocused(false);
       }
    }

    const onItemClick = (index) => {
        if(onChange && typeof onChange === 'function') {
            onChange(index);
        }
        setFocused(false);
    }

    const handleFocus = () => {
        if(focused && !editable) setFocused(false);
        if(!editable) return;
        setFocused(!focused);
    }

    const handleLabel = function () {
        if(options && selected > -1 && options[selected]?.hidden === false){
            return options[selected].label;
        }
        return placeholder || "Seleccionar";
    }

    useEffect(() => {
        if(focused) document.addEventListener('click', onDocumentClick);
        else document.removeEventListener('click', onDocumentClick);
        return () => document.removeEventListener('click', onDocumentClick);
    }, [focused]);

    return (
        <CustomSelect>
            <SelectIndicator editable={editable} focused={focused} disabled={disabled} onClick={handleFocus}>
                <span>{handleLabel()}</span>
                <Icon><SelectIcon /></Icon>
            </SelectIndicator>
            <SelectItems focused={focused} data-rule="custom-select">
                {!disabled && options?.length > 0 && options.map((item, key) => (
                    <Item
                        selected={(key === selected)}
                        onClick={() => onItemClick(key)}
                        key={item.value}
                        data-rule="custom-select"
                        hidden={item.hidden}
                    >
                        {item.label}
                    </Item>
                ))}
            </SelectItems>
        </CustomSelect>
    );
}